var App = App || {};
App['DEBUG'] = false;

(function ($){
	var $body,
			OCE,
			$header;

	var triggerEvent = function($, eventName){
    if(App.DEBUG)
      console.log("--- "+eventName+" ---");
    $(window).trigger(eventName);
	};
	const debounce = (func, wait, immediate) => {
  	var timeout;
  	return function() {
  		var context = this, args = arguments;
  		var later = function() {
  			timeout = null;
  			if (!immediate) func.apply(context, args);
			};
  		var callNow = immediate && !timeout;
  		clearTimeout(timeout);
  		timeout = setTimeout(later, wait);
  		if (callNow) func.apply(context, args);
  	};
	};
	const throttle = (func, limit) => {
		let lastFunc
		let lastRan
		return function() {
			const context = this
			const args = arguments
			if (!lastRan) {
				func.apply(context, args)
				lastRan = Date.now()
			} else {
				clearTimeout(lastFunc)
				lastFunc = setTimeout(function() {
					if ((Date.now() - lastRan) >= limit) {
						func.apply(context, args)
						lastRan = Date.now()
					}
				}, limit - (Date.now() - lastRan))
			}
		}
	}
  var smartScroll = throttle(function() { triggerEvent($, "smartScroll"); }, 200);
	var smartResize = debounce(function() { triggerEvent($, "smartResize"); }, 250);

	$(document).ready(function() {
		// Staticizzo variabili $ (in modo da evitare continue intrusioni nel dom)
		$body = $('body');
		$header = $('body').find('.site-header');

		commonFnHandler();
		menuManager();

		// FRONT PAGE
		if($body.hasClass('home')){
			initMainSlider();
			initProductSlider();
		}
	});

	function initSmartListener(){
    $(window).on('scroll', smartScroll);
		$(window).on('resize', smartResize);
  }

	function commonFnHandler(){
		setBrowserClass();
		initSmartListener();
		setVHFix();
		// set hover class
		$body.find('.wrapper-link').hover( hoverClassIn, hoverClassOut );

		OCE = gajus.orientationchangeend({});
		// Attach event listener to the "orientationchangeend" event.
		OCE.on('orientationchangeend', () => {
			setVHFix();
		});
	}

	function hoverClassIn(){
		$(this).find('.hoverable').addClass('hover');
	}

	function hoverClassOut(){
		$(this).find('.hoverable').removeClass('hover');
	}

	function menuManager(){
		var lastScrollTop = 0;
		var delta = 20;
		var navbarHeight = 88;

		// disabilitato a seguito di richiesta del cliente, lascio commentato nel caso servisse nuovamente
		//setMenuMobile();

		$(window).on("smartScroll", function(){
			var st = $(window).scrollTop();
			// Make sure they scroll more than delta
			if(Math.abs(lastScrollTop - st) <= delta)
			return;
			if (st > navbarHeight){
				compactMenu();
			}else{
				unCompactMenu()
			}
			lastScrollTop = st;
    });
	}

	function compactMenu(){
		$header.addClass("compact");
	}
	function unCompactMenu(){
		$header.removeClass("compact");
	}

	/*  disabilitato a seguito di richiesta del cliente, lascio commentato nel caso servisse nuovamente
	const setMenuMobile = () => {
		$('.navbar-mobile .menu-item-has-children').each( function(){
			let $that = $(this);
			$that.find('> a')
				.attr('data-toggle', 'collapse')
				.attr('href', '#submenu' + $that.find('> a').data('id'));
			$that.find('> .sub-menu')
				.attr('id', 'submenu' + $that.find('> a').data('id'))
		} );
	}*/

	const initMainSlider = () => {
		let mainSlider = new Swiper('.main-slider', {
			init: false,
			loop: true,
			speed: 800,
			threshold: 20,
			autoplay: {
				delay: 5000,
				disableOnInteraction: true,
			},
      pagination: {
        el: '.swiper-pagination',
        clickable: false,
        renderBullet: function (index, className) {
					let label = '', link;
					if(typeof App.sliderLabels !== "undefined" && !!App.sliderLabels[index]){
						label = App.sliderLabels[index];
					}
					link = App.sliderLinks[index] ? App.sliderLinks[index] : "#";
          return '<div class="' + className + '"><div class="label">'+label+'</div><a href="'+link+'" class="cta no-underline">MEHR</a><span class="number">' + (index + 1) + '</span><span class="line"></span></div>';
				}
			}
		});
		mainSlider.on('init', function(){
			// Set full anchor wrapper link
			$('.main-slider').find('.swiper-slide').each(function(){
				let $that = $(this),
						link,
						index = $that.data('swiper-slide-index'),
						$wrapperLink;
				link = App.sliderLinks[index] ? App.sliderLinks[index] : "#";
				$wrapperLink = $that.find('.slide-wrapper-link');
				$wrapperLink.attr('href', link);
				// ie object fit fallback
				if( $('html').hasClass('no-objectfit') ){
					$wrapperLink.css('background-image', 'url("' + $wrapperLink.data('bg') + '")' );
				}
			});

		});
		mainSlider.init();
		// set pagination
		let $pagination = $body.find('.swiper-pagination-bullet');
		$pagination.on('click', function(event){
			var index = $(this).index() * mainSlider.params.slidesPerGroup;
			if (mainSlider.params.loop) { index += mainSlider.loopedSlides; }
			mainSlider.slideTo(index);
		})
	}

	const initProductSlider = () => {
		let productSlider = new Swiper('.product-slider', {
			loop: true,
			effect: 'fade',
			threshold: 20,
			speed: 1200,
			fadeEffect: {
				crossFade: true
			},
			autoplay: {
				delay: 5000,
				disableOnInteraction: true,
			},
			pagination: {
        el: '.swiper-pagination',
        type: 'fraction',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
		});
	}

	const setVHFix = () => {
    var vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', vh+'px');
  }

	const setBrowserClass = () => {
		const ua = window.navigator.userAgent;
		if(ua.indexOf('Trident/') > 0 || ua.indexOf('MSIE ') > 0){
			$('html').addClass('ie');
		}
		if(ua.indexOf('Edge/') > 0){
			$('html').addClass('edge');
		}

	}

})(jQuery);
